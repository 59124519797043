import Vue from "vue"
import vueRouter from "vue-router"

Vue.use(vueRouter)

const router = new vueRouter({
    routes:[
        {
            path: '/',
            component: () => import('@/pages/home.vue')
        }
    ]
})

export default router