<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
